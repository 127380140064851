import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import styled from '@emotion/styled';
import Heading from '@greenberry/salal/components/Heading';
import Autosuggest from 'react-autosuggest';
import { debounce } from 'debounce';
import theme from '@greenberry/salal/theme';
import searchIcon from './icon';
import { getSuggestions } from './utils';

const MAX_WIDTH = 400;

const Wrapper = styled.div`
  display: flex;
  max-width: ${MAX_WIDTH}px;
  width: 100%;
  position: relative;
  margin-bottom: ${theme.spacing('m')};

  .react-autosuggest__container {
    width: 100%;
    display: block;
  }
`;

const SuggestionWrapper = styled.div`
  position: absolute;
  background-color: #fff;
  margin-top: ${theme.spacing('s')};
  border-radius: ${theme.get('borderRadius')};
  box-shadow: 0 5px 10px 0 rgba(68, 68, 68, 0.1);
  z-index: 1;
  max-width: ${MAX_WIDTH}px;
  width: 100%;
`;

const ResultWrapper = styled.div`
  padding: ${theme.space('s')};
  background-color: ${({ active }) =>
    active ? theme.color('secondary.3') : 'transparent'};

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;

  &:last-child {
    border-bottom-left-radius: ${theme.get('borderRadius')};
    border-bottom-right-radius: ${theme.get('borderRadius')};
  }
`;

const ResultValue = styled.span`
  font-size: ${theme.fs('s')};
`;

const PVID = styled(ResultValue)`
  opacity: 0.5;

  &:after {
    content: '·';
    font-size: 26px;
    margin: 0 0.2em;
  }
`;

const Name = styled(ResultValue)`
  flex-grow: 1;
`;

const Address = styled(ResultValue)`
  white-space: nowrap;
  opacity: 0.5;
`;

const Row = styled.div`
  flex-grow: 0;
  line-height: 0.5;
`;

export const inputStyles = {
  width: '100%',
  padding: [
    theme.spacing('s'),
    theme.spacing('s'),
    theme.spacing('s'),
    parseFloat(theme.spacing('m')) * 2 + 'rem',
  ].join(' '),
  borderRadius: theme.get('borderRadius'),
  backgroundImage: `url("${searchIcon}")`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '20px',
  backgroundPosition: `${theme.spacing('s')} 50%`,
  border: `1px solid ${theme.color('secondary.1')}`,
  outline: 'none',
};

const getSuggestionValue = suggestion => suggestion.partnership.name;
const renderSuggestion = (suggestion, { isHighlighted }) => (
  <ResultWrapper active={isHighlighted}>
    <Name>{path(['partnership', 'name'], suggestion)}</Name>
    <Row>
      <PVID>{path(['partnership', 'externalId'], suggestion)}</PVID>
      <Address>
        {path(['partnership', 'zipcode'], suggestion)},&nbsp;
        {path(['partnership', 'city'], suggestion)}
      </Address>
    </Row>
  </ResultWrapper>
);

const renderSuggestionContainer = ({
  suggestions = [],
  containerProps,
  children,
  query,
}) => {
  const isActive = suggestions.length > 0;

  return (
    <SuggestionWrapper {...containerProps}>
      {isActive && (
        <Heading
          size="s"
          style={{
            fontWeight: '100',
            padding: theme.spacing('s'),
            color: '#000',
            marginBottom: 0,
          }}
        >
          {`${suggestions.length} samenwerkingsverbanden gevonden voor '${query}'`}
        </Heading>
      )}
      {children}
    </SuggestionWrapper>
  );
};

renderSuggestionContainer.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  containerProps: PropTypes.shape({}).isRequired,
  children: PropTypes.node.isRequired,
  query: PropTypes.string.isRequired,
};

const onSuggestionsFetchRequested = setSuggestions =>
  debounce(async ({ value }) => {
    const suggestions = await getSuggestions(value);
    setSuggestions(suggestions);
  }, 400);

const onSuggestionsClearRequested = setSuggestions => () => {
  setSuggestions([]);
};

const Search = ({ onItemSelect }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState('');

  const inputProps = {
    placeholder: 'Bijv. Amstelveen, Koers VO, PO2709',
    onChange: (_, { newValue }) => setValue(newValue),
    style: inputStyles,
    value,
  };

  return (
    <Wrapper>
      <Autosuggest
        suggestions={suggestions}
        alwaysRenderSuggestions={value !== ''}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested(
          setSuggestions
        )}
        onSuggestionsClearRequested={onSuggestionsClearRequested(
          setSuggestions
        )}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={props =>
          renderSuggestionContainer(Object.assign({ suggestions }, props))
        }
        onSuggestionSelected={onItemSelect}
        inputProps={inputProps}
      />
    </Wrapper>
  );
};

Search.propTypes = {
  onItemSelect: PropTypes.func.isRequired,
};

export default Search;
