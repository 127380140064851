import 'isomorphic-fetch';

import {
  compose,
  has,
  map,
  pipe,
  prop,
  replace,
  reverse,
  sortBy,
  take,
  uniq,
} from 'ramda';

const formatHit = ({ _source, _score }) => {
  const partnership = has('partnership', _source)
    ? prop('partnership', _source)
    : _source;

  return {
    partnership,
    score: _score,
  };
};

const formatHits = pipe(
  prop('hits'),
  map(formatHit),
  uniq,
  sortBy(prop('score')),
  reverse,
  take(8)
);

const sanitizeQuery = pipe(compose(replace('/', ' ')), encodeURIComponent);

export const getSuggestions = async value => {
  if (value === '') {
    return [];
  }

  const creds = btoa(
    `${process.env.GATSBY_ES_USER}:${process.env.GATSBY_ES_PASSWORD}`
  );

  const query = sanitizeQuery(value);

  const { hits } = await fetch(
    `${process.env.GATSBY_ES_HOST}/partnerships/_search?q=*${query}*&size=20`, // Add * to make all search fuzzy
    {
      headers: {
        Authorization: `Basic ${creds}`,
      },
    }
  ).then(res => res.json());

  return formatHits(hits);
};
